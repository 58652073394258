import 'antd/dist/reset.css'
import './App.css'
import { useState, useEffect } from 'react'
import { getSessionId } from './utils'
import {
  Button,
  Card,
  Skeleton,
  Space,
  Spin,
  notification,
  Row,
  Col,
  Statistic,
  Divider,
} from 'antd'
import { useWindowSize } from '@react-hook/window-size'
import { Collapse } from 'antd'
const { Panel } = Collapse

let didInit = false

function windowWidthToCardWidth(width) {
  console.log(width)
  if (width < 620) {
    return width - 20
  } else {
    return 600
  }
}

function App() {
  const [loading, setLoading] = useState(true)
  const [imageLoading, setImageLoading] = useState(true)
  const [imageData, setImageData] = useState({})
  const [imageLoadedTime, setImageLoadedTime] = useState(0)
  const [windowWidth, windowHeight] = useWindowSize()
  const [stats, setStats] = useState({
    fakeCorrect: 0,
    fakeIncorrect: 0,
    realCorrect: 0,
    realIncorrect: 0,
  })
  const [lastResponse, setLastResponse] = useState({
    time: Date.now(),
  })
  const [showResults, setShowResults] = useState(false)

  useEffect(() => {
    if (!didInit) {
      didInit = true
      console.log('loading...')
      fetch('https://apxgxznmunqtbmumadkx.functions.supabase.co/get-image')
        .then((res) => res.json())
        .then((data) => {
          setImageData(data)
          setImageLoading(true)
          setLoading(false)
        })
    }
  }, [])

  useEffect(() => {
    if (loading && didInit) {
      didInit = true
      console.log('loading...')
      fetch('https://apxgxznmunqtbmumadkx.functions.supabase.co/get-image')
        .then((res) => res.json())
        .then((data) => {
          setImageData(data)
          setImageLoading(true)
          setLoading(false)
        })
    }
  }, [loading])

  useEffect(() => {
    setTimeout(() => {
      setShowResults(false)
    }, 1000)
  }, [lastResponse.time])

  useEffect(() => {
    if (!imageLoading) {
      setImageLoadedTime(Date.now())
    }
  }, [imageLoading])

  async function submitResponse(imageData, isFake) {
    setLoading(true)
    const timeSpentLookingAtImage = Date.now() - imageLoadedTime
    console.warn(timeSpentLookingAtImage)

    const res = await fetch('https://clever-mouse-33.deno.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: imageData.id,
        url: imageData.url,
        is_fake: isFake,
        sessionId: getSessionId(),
        timeSpentLookingAtImage,
      }),
    }).then((response) => response.json())

    if (res.correct) {
      setLastResponse({
        time: Date.now(),
        correct: true,
      })
      setShowResults(true)
      /*notification.success({
        message: 'Correct!',
      })*/
      setStats({
        ...stats,
        fakeCorrect: isFake ? stats.fakeCorrect + 1 : stats.fakeCorrect,
        realCorrect: !isFake ? stats.realCorrect + 1 : stats.realCorrect,
      })
    } else {
      /*notification.error({
        message: 'Incorrect!',
      })*/
      setLastResponse({
        time: Date.now(),
        correct: false,
      })
      setShowResults(true)
      setStats({
        ...stats,
        fakeIncorrect: isFake ? stats.fakeIncorrect + 1 : stats.fakeIncorrect,
        realIncorrect: !isFake ? stats.realIncorrect + 1 : stats.realIncorrect,
      })
    }
  }

  return (
    <div className="App">
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100vw',
        }}
      >
        <h1>Was this image generated by AI?</h1>
        <Space
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            marginBottom: 8,
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Card
              style={{
                width: windowWidthToCardWidth(windowWidth),
                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
              }}
              bodyStyle={{ padding: 0 }}
              cover={
                <>
                  <div
                    style={{
                      height: windowWidthToCardWidth(windowWidth),
                      display: showResults ? 'flex' : 'none',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <img
                      style={{
                        height: '64px',
                        display: lastResponse.correct ? 'block' : 'none',
                      }}
                      src={'correct.svg'}
                    />
                    <img
                      style={{
                        height: '64px',
                        display: lastResponse.correct ? 'none' : 'block',
                      }}
                      src={'incorrect.svg'}
                    />
                    <h1
                      style={{
                        color: lastResponse.correct ? '#31af90' : '#c93636',
                      }}
                    >
                      {lastResponse.correct ? 'CORRECT' : 'WRONG'}
                    </h1>
                  </div>
                  {showResults ? (
                    <></>
                  ) : loading || imageLoading ? (
                    <Spin size="large" tip={'Loading'}>
                      <Skeleton.Node
                        active={true}
                        style={{
                          width: windowWidthToCardWidth(windowWidth),
                          height: windowWidthToCardWidth(windowWidth),
                        }}
                      />
                    </Spin>
                  ) : (
                    <></>
                  )}
                  {imageLoading && (
                    <>
                      <link
                        rel="preload"
                        as="image"
                        href={imageData.url}
                        onLoad={() => setImageLoading(false)}
                      />
                    </>
                  )}
                  {!showResults && !imageLoading && !loading && (
                    <img
                      alt="fake or real"
                      style={{
                        width: windowWidthToCardWidth(windowWidth),
                        height: windowWidthToCardWidth(windowWidth),
                      }}
                      src={imageData.url}
                    />
                  )}
                </>
              }
              actions={[
                <Button
                  type="link"
                  size="large"
                  onClick={() => submitResponse(imageData, true)}
                  disabled={loading || imageLoading}
                >
                  Yes
                </Button>,
                <Button
                  type="link"
                  size="large"
                  onClick={() => submitResponse(imageData, false)}
                  disabled={loading || imageLoading}
                >
                  No
                </Button>,
              ]}
            />
          </div>
          <Row
            gutter={16}
            style={{ width: windowWidthToCardWidth(windowWidth) }}
          >
            <Col span={16} style={{ paddingLeft: 0 }}>
              <Card
                bordered={false}
                bodyStyle={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: 128,
                }}
              >
                <Space>
                  <Statistic
                    title="Correct guesses on real images"
                    value={stats.realCorrect}
                    suffix={`/ ${stats.realCorrect + stats.realIncorrect}`}
                  />
                  <Statistic
                    title="Correct guesses on fake images"
                    value={stats.fakeCorrect}
                    suffix={`/ ${stats.fakeCorrect + stats.fakeIncorrect}`}
                  />
                </Space>
              </Card>
            </Col>
            <Col span={8} style={{ paddingRight: 0 }}>
              <Card
                bordered={false}
                bodyStyle={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: 128,
                }}
              >
                <Statistic
                  title="Accuracy"
                  precision={1}
                  suffix="%"
                  value={
                    ((stats.realCorrect + stats.fakeCorrect) /
                      (stats.realCorrect +
                        stats.fakeCorrect +
                        stats.realIncorrect +
                        stats.fakeIncorrect)) *
                      100 || 0
                  }
                />
              </Card>
            </Col>
          </Row>
        </Space>
        <Divider />
        <Space style={{ marginBottom: 16 }}>
          <Collapse
            style={{
              width: windowWidthToCardWidth(windowWidth),
              textAlign: 'left',
            }}
          >
            <Panel header="Poster answers" key="4">
              <img
                style={{ maxWidth: '100%', marginBottom: '10px' }}
                src="poster_b.png"
              ></img>
              <img style={{ maxWidth: '100%' }} src="poster_c.png"></img>
            </Panel>
            <Panel header="How were these images generated?" key="1">
              <p>
                The real images are from the{' '}
                <a
                  href="https://laion.ai/blog/laion-5b/"
                  target="_blank"
                  rel="noreferrer"
                >
                  LAION-5B
                </a>{' '}
                dataset. The AI images are generated using Stable Diffusion
                txt2img. The prompts used to generate the images are a mix
                between image descriptions from LAION-5B, BLIP, and CLIP guided
                nearest neighbor search to find words with a high CLIP
                similarity to the corresponding real image. The Stable Diffusion
                models used are 1.4, 2.0, and 2.1.
              </p>
            </Panel>
            <Panel header="What is the purpose of this website?" key="2">
              <p>
                We are conducting a research project with the goal of creating a
                robust model with the ability to distinguish between real images
                and images generated by Stable Diffusion. As part of our
                research, we want to compare the performance of our model to
                human performance.
              </p>
            </Panel>
            <Panel header="Who are you?" key="3">
              <p>
                We are two students from the IT University of Copenhagen. Feel
                free to reach out if you have any questions/comments:
              </p>
              <ul>
                <li>
                  Felix Qvist: <a href="mailto:feqv@itu.dk">feqv@itu.dk</a>
                </li>
                <li>
                  Mikkel Kailow: <a href="mailto:mkai@itu.dk">mkai@itu.dk</a>
                </li>
              </ul>
            </Panel>
          </Collapse>
        </Space>
      </div>
    </div>
  )
}

export default App
